import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Col, Row } from 'reactstrap';
import { QTooltip, QTooltipSubTitle, QTooltipTitle } from '_Components/Tooltips/QTooltip';
import QBadge from '_Components/Tooltips/BadgeBenchmarkTooltip/Badge/Badge';

const TooltipBench = styled.span`
  cursor: pointer;
`;

const TooltipDate = styled.span`
  font-size: 12px;
  color: #928fb0;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
`;

const TooltipNote = styled.span`
  font-weight: bold;
  font-size: 24px;
  color: #2e096b;
  text-align: left;
`;

// TODO : optimize with a loop in render and split logic and view + export styled component ?
class BadgeBenchmarkTooltip extends React.Component {
  constructor(props) {
    super(props);
    const { idTooltip, index } = this.props;
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      id: `${idTooltip}-${index}`,
    };
  }

  toggle() {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  }

  render() {
    const { t, i18n, data, title, badgeShow } = this.props;
    const { id, tooltipOpen } = this.state;
    const { periodBefore, yearBefore, unit } = data || {};
    const getDateFormat = date => {
      const date1 = moment.parseZone(date).format('YYYY-MM-DD[T]HH:mm:ss');
      return moment(date1)
        .locale(i18n.language)
        .format('LL');
    };
    return (
      (id &&
        (badgeShow === undefined || badgeShow === true) &&
        ((periodBefore && periodBefore.value !== null) || (yearBefore && yearBefore.value !== null)) && (
          <TooltipBench>
            <QBadge id={id} delta={yearBefore ? yearBefore?.delta : periodBefore?.delta} />
            <QTooltip placement="auto" autohide={false} isOpen={tooltipOpen} target={id} hideArrow toggle={this.toggle}>
              <Row>
                <Col>
                  <QTooltipTitle>{t(title)}</QTooltipTitle>
                </Col>
              </Row>
              {yearBefore && yearBefore.value !== null && (
                <Row>
                  <Col xs={12}>
                    <QTooltipSubTitle>{t('Year-1')}</QTooltipSubTitle>
                  </Col>
                  <Col xs={12}>
                    <TooltipDate>
                      {`${t('From')} ${getDateFormat(yearBefore.period.startDate)} ${t('to')} ${getDateFormat(
                        yearBefore.period.endDate,
                      )}`}
                    </TooltipDate>
                  </Col>
                  <Col xs={12} className="my-3">
                    <QBadge delta={yearBefore.delta} id={id} />
                  </Col>
                  <Col xs={12}>
                    <TooltipNote>
                      {yearBefore.value}
                      {unit && (unit.unit || ` / ${unit.max}`)}
                    </TooltipNote>
                  </Col>
                </Row>
              )}
              {periodBefore &&
                periodBefore.value !== undefined &&
                yearBefore !== null &&
                yearBefore.value !== undefined && (
                  <Row>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                )}
              {periodBefore && periodBefore.value !== null && (
                <Row>
                  <Col xs={12}>
                    <QTooltipSubTitle>{t('Period-1')}</QTooltipSubTitle>
                  </Col>
                  <Col xs={12}>
                    <TooltipDate>
                      {`${t('From')} ${getDateFormat(
                        periodBefore ? periodBefore.period.startDate : yearBefore.period.startDate,
                      )} ${t('to')} ${getDateFormat(
                        periodBefore ? periodBefore.period.endDate : yearBefore.period.endDate,
                      )}`}
                    </TooltipDate>
                  </Col>
                  <Col xs={12} className="my-3">
                    <QBadge delta={periodBefore ? periodBefore?.delta : yearBefore?.delta} id={id} />
                  </Col>
                  <Col xs={12}>
                    <TooltipNote>
                      {periodBefore ? periodBefore.value : yearBefore.value}
                      {unit && (unit.unit || ` / ${unit.max}`)}
                    </TooltipNote>
                  </Col>
                </Row>
              )}
            </QTooltip>
          </TooltipBench>
        )) ||
      null
    );
  }
}

export default withTranslation('card')(BadgeBenchmarkTooltip);

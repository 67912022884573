/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const ActionTypes = {
  SCROLL_DOWN: 'SCROLL_DOWN',
  SEND_DATA_DATE_PROJECT_CALL_API: 'SEND_DATA_DATE_PROJECT_CALL_API',
  SEND_PARENT_KEY_FILTER: 'SEND_PARENT_KEY_FILTER',
  SEND_KEY_FILTER_OF_PAGE: 'SEND_KEY_FILTER_OF_PAGE',
  UPDATE_RESULTS_FROM_FILTER: 'UPDATE_RESULTS_FROM_FILTER',
  SEND_KEYS_FILTER: 'SEND_KEYS_FILTER',
  SEND_EMOTION_SELECTED: 'SEND_EMOTION_SELECTED',
  SEND_SENTIMENT_SELECTED: 'SEND_SENTIMENT_SELECTED',
  SEND_SELECTION_SELECTED: 'SEND_SELECTION_SELECTED',
  KEYWORDS_UPDATED: 'KEYWORDS_UPDATED',
  DATES_ON_CHANGE: 'DATES_ON_CHANGE',
  SEND_DATE_PROJECT: 'SEND_DATE_PROJECT',
  SEND_DATA_PROJECT: 'SEND_DATA_PROJECT',
  EINDEX_ITEM_ON_CLICK: 'EINDEX_ITEM_ON_CLICK',
  UPDATE_ROUTE: 'UPDATE_ROUTE',
  UPDATE_NB_MENTIONS_PAGE: 'UPDATE_NB_MENTIONS_PAGE',
  SEND_daily_RESULTS_EINDEX: 'SEND_daily_RESULTS_EINDEX',
  SEND_daily_RESULTS_EMOTION: 'SEND_daily_RESULTS_EMOTION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  STOCK_LIST_PROJECTS: 'STOCK_LIST_PROJECTS',
  SET_PROJECT_CURRENT: 'SET_PROJECT_CURRENT',
  SET_FUTURE_PROJECT_TO_SELECT: 'SET_FUTURE_PROJECT_TO_SELECT',
  GET_USER_SETTING: 'GET_USER_SETTING',
  SEND_EMOTION_ONHOVER: 'SEND_EMOTION_ONHOVER',
  SELECT_ANALYSIS_AXIS: 'SELECT_ANALYSIS_AXIS',
};

// TODO : VOIR POUR PARTAGER LES VARIABLES ENTRE LE JS ET LE CSS
export const EMOTIONS = {
  happiness: {
    color: '#DB2156',
    icon: 'HAPPINESS',
  },
  surprise: {
    color: '#FF8104',
    icon: 'SURPRISE',
  },
  calm: {
    color: '#F5D800',
    icon: 'CALM',
  },
  fear: {
    color: '#8BDB00',
    icon: 'FEAR',
  },
  sadness: {
    color: '#0EC3E3',
    icon: 'SADNESS',
  },
  anger: {
    color: '#007AFF',
    icon: 'ANGER',
  },
  disgust: {
    color: '#222C66',
    icon: 'DISGUST',
  },
};

export const SENTIMENTS = {
  positive: {
    color: '#8bdb00',
    icon: 'HAPPINESS',
  },
  neutral: {
    color: '#c3c8d9',
    icon: 'CALM',
  },
  negative: {
    color: '#000000',
    icon: 'DISGUST',
  },
};

export const emotions = [
  {
    name: 'happiness',
    value: 'happiness',
    color: '#DB2156',
    icon: 'EMOTION_HAPPINESS',
  },
  {
    name: 'surprise',
    value: 'surprise',
    color: '#FF8104',
    icon: 'EMOTION_SURPRISE',
  },
  {
    name: 'calm',
    value: 'calm',
    color: '#F5D800',
    icon: 'EMOTION_CALM',
  },
  {
    name: 'fear',
    value: 'fear',
    color: '#8BDB00',
    icon: 'EMOTION_FEAR',
  },
  {
    name: 'sadness',
    value: 'sadness',
    color: '#0EC3E3',
    icon: 'EMOTION_SADNESS',
  },
  {
    name: 'anger',
    value: 'anger',
    color: '#007AFF',
    icon: 'EMOTION_ANGER',
  },
  {
    name: 'disgust',
    value: 'disgust',
    color: '#222C66',
    icon: 'EMOTION_DISGUST',
  },
];

export const sentiments = [
  {
    name: 'positive',
    value: 'positive',
    color: '#8BDB00',
    icon: 'SENTIMENT_POSITIVE',
  },
  {
    name: 'neutral',
    value: 'neutral',
    color: '#6D717D',
    icon: 'SENTIMENT_NEUTRAL',
  },
  {
    name: 'negative',
    value: 'negative',
    color: '#000000',
    icon: 'SENTIMENT_NEGATIVE',
  },
];

export const SELECTIONS = {
  enchantement: {
    color: EMOTIONS.happiness.color,
    icon: 'FILTER_ENCHANTEMENT',
  },
  effet_wow: {
    color: EMOTIONS.surprise.color,
    icon: 'FILTER_WOW',
  },
  stories: {
    color: EMOTIONS.calm.color,
    icon: 'FILTER_STORIES',
  },
  irritation: {
    color: EMOTIONS.anger.color,
    icon: 'INTERFACE_IRRITANTS',
  },
  attrition: {
    color: EMOTIONS.disgust.color,
    icon: 'FILTER_CHURN',
  },
};

export const selections = [
  {
    name: 'Enchantment',
    value: 'ENCHANTEMENT',
    color: EMOTIONS.happiness.color,
    icon: 'FILTER_ENCHANTEMENT',
  },
  {
    name: 'WOW effect',
    value: 'EFFET_WOW',
    color: EMOTIONS.surprise.color,
    icon: 'FILTER_WOW',
  },
  {
    name: 'Stories',
    value: 'STORIES',
    color: EMOTIONS.calm.color,
    icon: 'FILTER_STORIES',
  },
  {
    name: 'Irritation',
    value: 'IRRITATION',
    color: EMOTIONS.anger.color,
    icon: 'INTERFACE_IRRITANTS',
  },
  {
    name: 'Churn',
    value: 'ATTRITION',
    color: EMOTIONS.disgust.color,
    icon: 'FILTER_CHURN',
  },
];

export const RECOMMENDATIONS = {
  reco_detractors: { color: '#db2156', icon: 'EMOTION_ANGER' },
  reco_neutrals: { color: '#ff8104', icon: 'EMOTION_CALM' },
  reco_promotors: { color: '#8bdb00', icon: 'EMOTION_HAPPINESS' },
};

export const sentimentsList = {
  positive: [EMOTIONS.happiness.icon, EMOTIONS.surprise.icon],
  neutral: [EMOTIONS.calm.icon],
  negative: [EMOTIONS.fear.icon, EMOTIONS.sadness.icon, EMOTIONS.anger.icon, EMOTIONS.disgust.icon],
};

export const positiveEmotions = {
  happiness: EMOTIONS.happiness,
  surprise: EMOTIONS.surprise,
};

export const neutralEmotions = {
  calm: EMOTIONS.calm,
};

export const negativeEmotions = {
  fear: EMOTIONS.fear,
  sadness: EMOTIONS.sadness,
  anger: EMOTIONS.anger,
  disgust: EMOTIONS.disgust,
};

export const negativeDimensions = ['fear', 'sadness', 'anger', 'disgust', 'negative', 'effort'];

export const EMAILS = {
  SUPPORT: 'support@qemotion.com',
};
